
import React from "react";
import styled from "styled-components";
import { MAX_WIDTH_PX } from "../consts";

const getTranslateX = (props) => {
  switch (props.position) {
    case "topLeft":
      return 0;
    case "topRight":
      return "-100%";
    default:
      return 0;
  }
}

const getTranslateY = (props) => {
  switch (props.position) {
    case "topLeft":
      return 0;
    case "topRight":
      return 0;
    default:
      return 0;
  }
}

const GradientWapperBox = styled.div`
  position: relative;
  width: 100%;
  max-width: ${MAX_WIDTH_PX}px;
  margin: 0 auto;
`;

const GradientBox = styled.div`
  height: 1px;
  width: 1px;
  position: absolute;

  ${(props) => {
    switch (props.position) {
      case "topLeft":
        return `
          top: 0;
          left: 0;
        `;
      case "topRight":
        return `
          top: 0;
          right: 0;
        `;
      case "bottomLeft":
        return `
          bottom: 0;
          left: 0;
        `;
      case "bottomRight":
        return `
        bottom: 0;
          right: 0;
        `;
      default:
        alert("Unknown gradient position.");
    }
  }}
  

  > div {
    position: absolute;
    top: 0px;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    transform: translate(${(props) => getTranslateX(props)}, ${(props) => getTranslateY(props)});
  }
`;

export function GradientWapper({ children }) {
  return <GradientWapperBox>{children}</GradientWapperBox>;
}

export function Gradient({ gradient }) {
  if (!gradient) {
    return null;
  }
  return (
    <GradientBox position={gradient.position} width={gradient.width} height={gradient.height}>
      <div style={{
        position: `absolute`,
        top: `${gradient.top}px`,
        left: `${gradient.left}px`,
        height: `${gradient.height}px`,
        width: `${gradient.width}px`,
        transform: `translate(${gradient.position.indexOf("Left") > -1 ? "-100%" : "0%"}, 0) rotate(${gradient.rotation}deg)`,
        borderRadius: gradient.shape,
        background: gradient.background,
        filter: `blur(42px) contrast(120%)`,
        opacity: `.6`
      }}></div>
    </GradientBox>
  )
}
