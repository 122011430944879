import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import { Gradient, GradientWapper } from "../components/gradients";
import { BLOG } from "../gradient-config";

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          summary
          date(formatString: "LL")
          coverImage {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;

const Page = styled.div``;

const ArticlesBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    display: flex;
    flex-direction: column;
    grid-gap: initial;
    width: 94%;
    margin: 0 auto;
  }
`;

const FeaturedArticle = styled.div`
 margin: 3rem 0;
 > a {
 
  display: flex;
  .thumbnail {
    max-width: 35%;
  }
  .title {
    color: #f1f2f4;
  }
  .content {
    padding: 0 2rem;
    > span,
    a {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    display: none;
  }
`;

const ArticlesBox = styled.div`
  > a {
    > span {
      display: block;
      &.date {
        padding-top: .5rem;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin-bottom: 2rem;
  }
`;

const BlogPage = ({ data }) => {
  const [gradients, setGradients] = React.useState({});
  const [isMobile, setIsMobile] = React.useState(false);

  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });

  React.useEffect(() => {
    setIsMobile(mediaQuery);
    setGradients(mediaQuery ? BLOG.MOBILE : BLOG.DESKTOP);
  }, [mediaQuery, isMobile]);

  const featuredPost = data?.allMarkdownRemark?.nodes?.[0];

  return (
    <Page>
      <Layout>
        <GradientWapper>
          <Gradient gradient={gradients[1]} />
          <Block noBottomPadding={true} contentFlexDirection={"column"}>
            <FeaturedArticle>
              <Link className="article-card" to={featuredPost.fields.slug}>
                <div className="thumbnail">
                  <GatsbyImage
                    image={getImage(featuredPost.frontmatter.coverImage)}
                    alt={featuredPost.frontmatter.title}
                    quality={66}
                    placeholder="none"
                    imgStyle={{borderRadius: 25}}
                  />             
                </div>
                <div className="content">
                  <span className="date f-m-m">
                    {featuredPost.frontmatter.date}
                  </span>
                  <span className="title hover-effect f-3xl">
                    {featuredPost.frontmatter.title}
                  </span>
                  <p className="f-l-m">
                    {featuredPost.frontmatter.summary}
                  </p>
                </div>
              </Link>
            </FeaturedArticle>

            <ArticlesBoxes>
              {data?.allMarkdownRemark?.nodes.map((article, key) => {
                if (key > (isMobile ? -1 : 0)) {
                  return (
                    <ArticlesBox key={key}>
                      <Link className="article-card" to={article.fields.slug}>
                        <GatsbyImage
                          image={{...getImage(article.frontmatter.coverImage), width: 870, height: 600}}
                          alt={article.frontmatter.title}
                          quality={66}
                          placeholder="none"
                          objectFit="cover"
                          imgStyle={{borderRadius: 25}}
                        />
                        <span className="date f-m-m">
                          {article.frontmatter.date}
                        </span>

                        <span className="title hover-effect f-l-b">
                          {article.frontmatter.title}
                        </span>
                      </Link>
                    </ArticlesBox>
                  );
                } else {
                  return null;
                }
              })}
            </ArticlesBoxes>
          </Block>
          <Gradient gradient={gradients[2]} />
        </GradientWapper>
      </Layout>
    </Page>
  );
};

export default BlogPage;
