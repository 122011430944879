export const HOMEPAGE = {
  "DESKTOP": {
    1: {
      position: `topRight`,
      top: 100,
      left: -550,
      height: 572,
      width: 1116,
      rotation: 37.5,
      shape: `35% 65% 28% 72% / 32% 44% 56% 68%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    },
    2: {
      position: `topLeft`,
      top: 0,
      left: 80,
      height: 1066,
      width: 770,
      rotation: 40,
      shape: `25% 75% 42% 58% / 29% 72% 28% 71%`,
      background: `linear-gradient(196.63deg, #3DD066 -6.71%, #98A0CF 45.65%, #4961EA 110.15%)`,
    },
    3: {
      position: `topRight`,
      top: 1000,
      left: -150,
      height: 750,
      width: 775,
      rotation: 25,
      shape: `50% 50% 30% 70% / 44% 58% 42% 56%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    },
    4: {
      position: `topLeft`,
      top: 10,
      left: 0,
      height: 1156,
      width: 322,
      rotation: 17.5,
      shape: `70% 30% 32% 68% / 40% 42% 58% 60%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    },
    5: {
      position: `topRight`,
      top: 280,
      left: -360,
      height: 400,
      width: 596,
      rotation: 220,
      shape: `42% 35% 70% 28% / 30% 22% 74% 24%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    }
  },
  "MOBILE": {
    1: {
      position: `topRight`,
      top: 0,
      left: -280,
      height: 400,
      width: 700,
      rotation: 37.5,
      shape: `35% 65% 28% 72% / 32% 44% 56% 68%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    },
    2: {
      position: `topLeft`,
      top: 0,
      left: 80,
      height: 1066,
      width: 770,
      rotation: 40,
      shape: `25% 75% 42% 58% / 29% 72% 28% 71%`,
      background: `linear-gradient(196.63deg, #3DD066 -6.71%, #98A0CF 45.65%, #4961EA 110.15%)`,
    },
    3: {
      position: `topRight`,
      top: 1380,
      left: -150,
      height: 750,
      width: 775,
      rotation: 25,
      shape: `50% 50% 30% 70% / 44% 58% 42% 56%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    },
    4: {
      position: `topLeft`,
      top: -320,
      left: 0,
      height: 1156,
      width: 322,
      rotation: 17.5,
      shape: `70% 30% 32% 68% / 40% 42% 58% 60%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    },
    5: {
      position: `topRight`,
      top: -180,
      left: -160,
      height: 400,
      width: 596,
      rotation: 220,
      shape: `42% 35% 70% 28% / 30% 22% 74% 24%`,
      background: `linear-gradient(196.93deg, #3DD066 -9.47%, #98A0CF 43.63%, #4961EA 109.03%)`,
    }
  }
}

export const BLOG = {
  "DESKTOP": {
    1: {
      position: `topRight`,
      top: -20,
      left: -180,
      height: 910,
      width: 510,
      rotation: 134,
      shape: `68% 32% 52% 48% / 72% 42% 58% 28%`,
      background: `linear-gradient(234.27deg, #3DD066 6.48%, #98A0CF 49.42%, #4961EA 102.32%)`,
    },
    2: {
      position: `topLeft`,
      top: 650,
      left: 60,
      height: 564,
      width: 795,
      rotation: -142,
      shape: `25% 74% 20% 30% / 41% 74% 22% 46%`,
      background: `linear-gradient(10.97deg, #3DD066 -16.19%, #98A0CF 52.55%, #4961EA 137.22%)`,
    },
  },
  "MOBILE": {
    1: {
      position: `topRight`,
      top: -20,
      left: -180,
      height: 600,
      width: 390,
      rotation: 134,
      shape: `68% 32% 52% 48% / 72% 42% 58% 28%`,
      background: `linear-gradient(234.27deg, #3DD066 6.48%, #98A0CF 49.42%, #4961EA 102.32%)`,
    },
    2: {
      position: `topLeft`,
      top: 800,
      left: 60,
      height: 680,
      width: 795,
      rotation: -142,
      shape: `25% 74% 20% 30% / 41% 74% 22% 46%`,
      background: `linear-gradient(10.97deg, #3DD066 -16.19%, #98A0CF 52.55%, #4961EA 137.22%)`,
    }
  }
}

export const ARTICLE = {
  "DESKTOP": {
    1: {
      position: `topRight`,
      top: -200,
      left: -260,
      height: 910,
      width: 510,
      rotation: -20,
      shape: `31% 38% 24% 74% / 30% 45% 46% 68% `,
      background: `linear-gradient(192.35deg, #3DD066 -8.73%, #98A0CF 51.14%, #4961EA 124.89%)`,
    },
    2: {
      position: `topLeft`,
      top: 110,
      left: 150,
      height: 564,
      width: 795,
      rotation: 210,
      shape: `21% 75% 18% 32% / 34% 74% 21% 40%`,
      background: `linear-gradient(190.53deg, #3DD066 -8.17%, #98A0CF 54.47%, #4961EA 131.63%)`,
    },
  },
  "MOBILE": { 1: {
    position: `topRight`,
    top: -80,
    left: -120,
    height: 300,
    width: 280,
    rotation: -20,
    shape: `31% 38% 24% 74% / 30% 45% 46% 68% `,
    background: `linear-gradient(192.35deg, #3DD066 -8.73%, #98A0CF 51.14%, #4961EA 124.89%)`,
  },
  2: {
    position: `topLeft`,
    top: 220,
    left: 100,
    height: 400,
    width: 280,
    rotation: 210,
    shape: `21% 75% 18% 32% / 34% 74% 21% 40%`,
    background: `linear-gradient(190.53deg, #3DD066 -8.17%, #98A0CF 54.47%, #4961EA 131.63%)`,
  },}
}